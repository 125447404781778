import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from '../Container'
import { Carousel } from '../Carousel'
import { FaExternalLinkAlt, FaFire } from 'react-icons/fa'
import * as s from './Listing.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

export const ListingFiresides = ({data, labels}) => {
  
  return (
    <section className={s.CarouselContainer} style={{ background: '#F8E4D2'}}>
      <Container>
        <div >
          <PrismicRichText field={labels.firesides_header?.richText}/>
        </div>
      
        <Carousel>
          {data.firesides?.sort((a,b) => {
                if(a.data?.Name < b.data?.Name) return -1;
                if(a.data?.Name > b.data?.Name) return 1;
                return 0;
              }).map((chat, i) => {
            const url = chat.data?.Web_URL && chat.data?.Web_URL[0] === 'w' ? `https://${chat.data?.Web_URL}` : chat.data?.Web_URL
            const cats = chat.data?.Career_Category 

            if(url) return (
              <PrismicLink href={url} className={s.FiresidesTile} key={i}>   
                <div className={s.FiresideImage}>
                  {chat.data.Illustration_File ? 
                    <GatsbyImage
                      image={chat.data.Illustration_File.localFiles[0].childImageSharp?.gatsbyImageData}
                      alt=""
                    /> :
                    <FaFire/>
                  }
                </div>
                <span className={s.FiresideTags}>{chat.data?.Indigenous_Community} | {cats}</span>
                <span className={s.FiresideName}>{chat.data?.Name}</span>
                <p>{chat.data?.Subtitle}</p>
                <button className={s.BlueBtn} style={{marginTop: 'auto'}}>{labels.fireside_btn_label} <FaExternalLinkAlt/></button>
              </PrismicLink>
          )})}
          
        </Carousel>
      </Container>
    </section>
  )
}
export const query = graphql`
  fragment AirtableFragFiresides on Airtable {
    data {
      Fireside_Chats {
        data {
          Career_Category
          Subtitle
          Province_Territory
          Name
          Web_URL
          Indigenous_Community: Community_Name
          Illustration_File {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 297, height: 178)
              }
            }
          }
        }
      }
    }
  }
  fragment CommonFragFiresides on PrismicListingCommon {
    data {
      firesides_header {
        richText
      }
      fireside_btn_label
    }
  }
`