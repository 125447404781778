import React, {useState,useEffect} from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from '../Container'
import { GatsbyImage } from 'gatsby-plugin-image'
import MarkerIcon from '../../images/marker-1-alt.png'
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import * as s from './Listing.module.scss'
import { FaExternalLinkAlt, FaMountain } from 'react-icons/fa'
import { BsFillMapFill } from 'react-icons/bs'

const defaultLocation = { lat: 60.219386, lng: -101.645961 }

export const ListingSites = ({data, labels, lang}) => {
  const [map, setMap] = React.useState(null);
  const [active, setActive] = useState(0)
  const sites = data.sites || []


  const center = sites.length > 0
    ? {lat: Number(sites[active].data.Lat), lng: Number(sites[active].data.Long)} 
    :defaultLocation

  const onLoad = React.useCallback(function callback(gmap) {
    const bounds = new google.maps.LatLngBounds(center);
    if(gmap && sites){ 
      sites.map(site => {
        bounds.extend({lat: Number(site.data.Lat), lng: Number(site.data.Long)})
      })
    }
    gmap.fitBounds(bounds)
    setMap(gmap)
  },[]);

  const onUnmount = React.useCallback(function callback(gmap) {
    setMap(null)
  }, [])

  return (
    <section className={s.CarouselContainer}>
      <Container>
        <div style={{marginBottom: 40}}>
          <PrismicRichText field={labels.historicsites_header?.richText}/>
        </div>
        <div className={s.FlexWrap} style={{flexWrap: 'wrap-reverse', alignItems: 'center'}}>
          <div  className={s.SiteList}>
            {sites.sort((a,b) => {
                if(a.data?.Name_EN < b.data?.Name_EN) return -1;
                if(a.data?.Name_EN > b.data?.Name_EN) return 1;
                return 0;
              }).map((site, i) => (
              <a onClick={() => setActive(i)} key={i} alt="" className={active === i ? s.SiteActive : s.SiteIdle}>
                <div className={s.SiteImage}>
                  {
                    site.data.Fullsize?.localFiles[0].childImageSharp ? 
                      <GatsbyImage
                        image={site.data.Fullsize?.localFiles[0].childImageSharp?.gatsbyImageData}
                        alt=""
                      />
                    :
                      <BsFillMapFill/>
                  }
                </div>
                <div className={s.SiteInfo}>
                  <span>{site.data.Name_EN}</span>
                </div>
              </a>
            ))}
          </div>
          <div className={s.MapWrap}>
            <GoogleMap
              options={{ mapId: "68283565ee8dd767", disableDefaultUI: true, fullscreenControl: true, zoomControl: true, keyboardShortcuts: false }}
              mapContainerStyle={{ width: '100%', aspectRatio: '1/1'}}
              onLoad={onLoad}
              center={center}
              onUnmount={onUnmount}
            >
              {sites.map((site, i) => (
                <Marker
                  position={{lat: Number(site.data.Lat), lng: Number(site.data.Long)}}
                  icon={MarkerIcon}
                  onClick={() => setActive(i)}
                >

                  {active === i && 
                    <InfoWindow>
                      <PrismicLink href={`https://explore150.tigweb.org/en/${site.data.Slug_EN}`} className={s.MapLink}>
                        {site.data.Name_EN}<FaExternalLinkAlt/>
                      </PrismicLink>
                    </InfoWindow>
                  }
                  
                </Marker>
              ))}

            </GoogleMap>
          </div>
        </div>
      </Container>
    </section>
  )
}
export const query = graphql`
  fragment AirtableFragSites on Airtable {
    data {
      Explore150_Sites {
        data {
          Name_EN
          Slug_EN
          Name_FR
          Slug_FR
          Lat
          Long
          ImageURL
          Fullsize {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 128, height: 90)
              }
            }
          }
        }
      }
    }
  }
  fragment CommonFragSites on PrismicListingCommon {
    data {
      historicsites_header {
        richText
      }
    }
  }
`