import React, {useState,useEffect,Children} from 'react'
import { FaChevronLeft, FaChevronRight} from 'react-icons/fa'
import { useSnapCarousel } from 'react-snap-carousel';
import useWindowDimensions  from "./useWindowDimensions";
import * as s from './Carousel.module.scss'
import { Container } from './Container';

export const Carousel = ({children}) => {
  const { scrollRef, pages, activePageIndex, next, prev, goTo } =
  useSnapCarousel();
  const arrayChildren = Children.toArray(children);
  return (
    <>
    {pages.length > 1 &&  
      <div className={s.Nav}>
          <button onClick={() => prev()} aria-label="Prev" className={s.PrevBtn}> <FaChevronLeft/> </button>
            <span>
              {activePageIndex + 1} / {pages.length}
            </span>
          <button onClick={() => next()} aria-label="next" className={s.NextBtn}> <FaChevronRight/> </button>
      </div>
      } 
      <ul
        ref={scrollRef}
        className={s.SlideTray}
        style={{
          display: 'flex',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
        }}
      >
        {arrayChildren.map((child, i) => (
          <li
            className={s.Slide}
            key={i}
          >
            {child}
          </li>
        ))}
      </ul>
    </>
  )
}