import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
} from "@react-google-maps/api";
import { Container } from '../Container';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as s from './Listing.module.scss'
import { FaPlayCircle, FaVolumeUp } from 'react-icons/fa';


const map_height = '293px'


export const ListingInfo = ({data,labels, altLayout=false, lang}) => {
  
  
  const [map, setMap] = React.useState(null);
  const polygon = data.type === 'polygon' ? data.coordinates : null;
  const marker = data.type === 'marker' ? data.coordinates : null;
  const audioSrc = data.pronunciation_audio ? data.pronunciation_audio.src : null;


  const onLoad = React.useCallback(function callback(gmap) {
    
    if(gmap && polygon){
      const bounds = new google.maps.LatLngBounds(); 
      polygon?.map(site => {
        bounds.extend(site)
      })
      gmap.fitBounds(bounds)
      setMap(gmap)
    } 
  },[]);

  const onUnmount = React.useCallback(function callback(gmap) {
    setMap(null)
  }, [])

  const playAudio = () => {
    if(audioSrc !== null) {
      const audio = new Audio(audioSrc);
      audio.play();
    }
    
  }

  return (
    <section className={s.ListingContainer}>
      <Container>
        <div className={s.FlexWrap}>
          <div className={s.Content}>
            <h1>{data.name}</h1>
            {data.pronunciation && (
              audioSrc ?
                <button onClick={playAudio} className={s.pronunciationAudio} aria-label="Listen to Pronunciation Guide"> 
                  <span >({data.pronunciation})</span>
                  <FaVolumeUp/>
                 
                </button>
              : 
                <span className={s.pronunciation}>({data.pronunciation})</span>
            )}
            {data.year && <span className={s.subtitle}>{labels.established_label} {data.year}</span>}
            {altLayout ?
              <div style={{marginTop: 40, maxWidth: '100%'}}>
                {
                  data.logo.childImageSharp &&
                    <GatsbyImage 
                      image={data.logo.childImageSharp?.gatsbyImageData} 
                      imgStyle={{objectFit: 'contain', objectPosition: 'left center'}}
                      alt=""
                    />
                                    
                } 
              </div>
            :
              <GoogleMap
                  
                options={{ mapId: "68283565ee8dd767", disableDefaultUI: true, fullscreenControl: true, zoomControl: true, keyboardShortcuts: false }}
                mapContainerStyle={{ height: map_height}}
                onLoad={onLoad}
                onUnmount={onUnmount}
                center={marker}
                zoom={7}
              >
                {marker && 
                  <Marker
                    position={marker}
                  />
                }
                {polygon && 
                  <Polygon
                    paths={polygon}
                    options = {{fillColor: data.color, strokeWeight: 0.5}}
                  />
                }
              </GoogleMap>
            }
            
            {data.description && <div>
              <PrismicRichText field={labels?.description_header?.richText}/>
              <p>{data.description}</p>
            </div>}
          </div>
          <div className={s.CTAWrap}>
            {data.resources.length > 0 && (
              <div className={s.CTA}>
                <PrismicRichText field={labels?.resources_header?.richText}/>
                <ul>
                  {data.resources.map((x, i) => (
                    <li key={i}>
                      <PrismicLink href={x.link} className={s.ResourceLink}>{x.label}</PrismicLink>
                    </li>
                  ))
                }
                   
                </ul>
              </div>
            )}
            {data.logo && !altLayout &&
              <div style={{marginTop: 40, maxWidth: '100%'}}>
                {
                  data.logo.childImageSharp &&
                    <GatsbyImage 
                      image={data.logo.childImageSharp?.gatsbyImageData} 
                      imgStyle={{objectFit: 'contain', objectPosition: 'left center'}}
                      alt=""
                    />
                                     
                } 
              </div>
            }
          </div>
        </div>
      </Container>
    </section>
  )
}
export const query = graphql`
  fragment AirtableFragInfo on Airtable {
    data {
      Name
      Year
      Latitude
      Longitude
      Coordinates
      Color
      Syllabics
      Pronunciation
      Pronunciation_Audio {
        localFiles {
          src: publicURL
        }
      }
      Treaty_Name_EN
      Description
      Field_6
      Field_7
      Field_8
      ID
      
      Native_Land_Link
      Link_1
      Link_1_Title
      Link_2
      Link_2_Title
      Link_3
      Link_3_Title
      Government_URL
      Community_URL
      Wikipedia_URL
      Logo {
        localFiles {
          
          extension
          src: publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        raw {
          url
        }
      }
    }
  }
  fragment CommonFragInfo on PrismicListingCommon {
    data {
      description_header {
        richText
      }
      established_label
      resources_header {
        richText
      }
    }
  }
`