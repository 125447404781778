import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/Layout'
import slugify from '../utils/slugify'
import { ListingInfo } from '../components/listing/ListingInfo'
import { ListingFiresides } from '../components/listing/ListingFiresides'
import { ListingCommunities } from '../components/listing/ListingCommunities'
import { ListingSites } from '../components/listing/ListingSites'
import { ListingPhotos } from '../components/listing/ListingPhotos'
import { ListingSources } from '../components/listing/ListingSources'
import { ListingVideos } from '../components/listing/ListingVideos'
import { LoadScript } from '@react-google-maps/api'
import * as s from '../components/listing/Listing.module.scss'
import { keyMergeListing } from '../utils/airtableKeyMerge'
import { ListingBusinesses } from '../components/listing/ListingBusinesses'

const tables = {
  tblA1YdPobD3JN7iG: 'Communities',
  tblNbzH8fyCHl9Uak: 'Treaties',
  tblD4d5rF383Bcbil: 'Territories',
  tblLvLuIFUJSVd3QS: 'Businesses'
}

const lib = ["places"];
const idG = ["614f2b924c037cf7"];
const key = 'AIzaSyAkMp-DRCWbGCpLz9uqAUJq6-1sVPJBcQ0'; 

const Loading = (
  <div className={s.loading}>
    <p>Map Loading</p>
    <div className={s.loadingSpinner}>
    </div>
  </div>
);

const ListingTemplate = ({ data }) => {
  if (!data) return null
  
  
  const pageCommonData = data.prismicListingCommon || {}
  const pageCommon = pageCommonData.data || {}
  
 
  const lang = pageCommonData.lang 

  const airtableContent = data.airtable || {}
  const table = airtableContent.table
  const airtable = airtableContent.data || {}
  const slug = airtable.Slug || slugify(airtable.Name)
  const keyMergedAirtable = keyMergeListing(airtableContent.table, airtable, lang.slice(0,2).toLowerCase());

  const url = `${tables[table].toLowerCase()}/${slug}`
  const type='listing'
  const alternateLanguages = []
  const lowerLang = lang.toLowerCase()
  
  pageCommonData.alternate_languages.map(alt => alternateLanguages.push({
    type : type,
    lang : alt.lang,
    uid : slug,
    table: tables[table].toLowerCase()
  }))
  const menu = data.prismicMenu || {}
  const title = `${airtable.Name || airtable.Treaty_Name_EN} | ${tables[airtableContent.table]} | Whose.Land`
 
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
      <Layout 
        menu={menu.data} 
        activeDocMeta={activeDoc} 
        title={title}
        description={null}
      >
        <LoadScript 
          googleMapsApiKey={key} 
          libraries={lib} 
          mapIds={idG} 
          loadingElement={Loading} 
          language={lowerLang.slice(0,2)}
        >
          <ListingInfo
            data={keyMergedAirtable}
            labels={pageCommon}
            lang={lowerLang}
          />
          {keyMergedAirtable.businesses && 
          <ListingBusinesses
            data={keyMergedAirtable}
            labels={pageCommon}
            lang={lowerLang}
          />}
          {keyMergedAirtable.firesides && 
          <ListingFiresides
            data={keyMergedAirtable}
            labels={pageCommon}
            lang={lowerLang}
          />}
          {keyMergedAirtable.videos &&
          <ListingVideos
            data={keyMergedAirtable}
            labels={pageCommon}
            lang={lowerLang}
          />}
          {keyMergedAirtable.communities && 
          <ListingCommunities
            data={keyMergedAirtable}
            labels={pageCommon}
            lang={lowerLang}
          />
          }
          {keyMergedAirtable.sites && 
          <ListingSites
            data={keyMergedAirtable}
            labels={pageCommon}
            lang={lowerLang}
          />}
          {keyMergedAirtable.Photos &&
          <ListingPhotos
            data={keyMergedAirtable}
            labels={pageCommon}
            lang={lowerLang}
          />}
          <ListingSources
            data={keyMergedAirtable}
            labels={pageCommon}
            lang={lowerLang}
          />
        </LoadScript>
      </Layout>
  )
}

export const query = graphql`
  query listingQuery($id: String, $lang: String, $table: String) {
    airtable(id: { eq: $id}) {
      id
      table
      data {
        Slug
      }
      ...AirtableFragInfo
      ...AirtableFragFiresides
      ...AirtableFragBusinesses
      ...AirtableFragVideos
      ...AirtableFragCommunities
      ...AirtableFragSites
      ...AirtableFragPhotos
      ...AirtableFragSources
    }
    prismicListingCommon(uid: { eq: $table}, lang: { eq: $lang}) {
      lang
      alternate_languages {
        uid
        type
        lang
      }
      uid
      ...CommonFragInfo
      ...CommonFragFiresides
      ...CommonFragBusinesses
      ...CommonFragVideos
      ...CommonFragCommunities
      ...CommonFragSites
      ...CommonFragPhotos
      ...CommonFragSources
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(ListingTemplate)
