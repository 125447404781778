import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from '../Container'

export const ListingPhotos = ({data, labels}) => {
  
  return (
    <section>
      <Container>
        <div>
          <PrismicRichText field={labels.photos_header?.richText}/>

          <>
            
          </>
        </div>
      </Container>
    </section>
  )
}
export const query = graphql`
  fragment AirtableFragPhotos on Airtable {
    data {
      Wikipedia_URL
    }
  }
  fragment CommonFragPhotos on PrismicListingCommon {
    data {
      photogallery_header {
        richText
      }
    }
  }
`