import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from '../Container'
import { Carousel } from '../Carousel'
import { FaExternalLinkAlt, FaStore } from 'react-icons/fa'
import * as s from './Listing.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import slugify from '../../utils/slugify'

export const ListingBusinesses = ({data, labels}) => {
  
  return (
    <section className={s.CarouselContainer} style={{ background: '#d3e9e5'}}>
      <Container>
        <div className={s.Content}>
          <PrismicRichText field={labels.businesses_header?.richText}/>
          <PrismicRichText field={labels.businesses_description?.richText}/>
        </div>
      
        <Carousel>
          {data.businesses?.sort((a,b) => {
                if(a.data?.Name < b.data?.Name) return -1;
                if(a.data?.Name > b.data?.Name) return 1;
                return 0;
              }).map((chat, i) => {
            const url = `../businesses/${slugify(chat.data.Name)}`

            const cats = chat.data?.Career_Category?.join(" - ")
            let tags = []
            if(chat.data?.Indigenous_Community?.data?.Name) tags.push(chat.data?.Indigenous_Community?.data?.Name);
            if(cats) tags.push(cats);
            if(url) return (
              <PrismicLink href={url} className={s.FiresidesTile} key={i}>   
                <div className={s.FiresideImage}>
                  {chat.data.Logo ? 
                    <GatsbyImage
                      image={chat.data.Logo.localFiles[0].childImageSharp?.gatsbyImageData}
                      alt=""
                    /> :
                    <FaStore/>
                  }
                </div>
                <span className={s.FiresideTags}>{tags.join(' | ')}</span>
                <span className={s.FiresideName}>{chat.data?.Name}</span>
                <p className={s.truncate}>{chat.data?.Subtitle}</p>
                <p className={s.MoreBtn}>
                  {labels.community_btn_label} <FaExternalLinkAlt/>
                </p>
              </PrismicLink>
          )})}
          
        </Carousel>
      </Container>
    </section>
  )
}
export const query = graphql`
  fragment AirtableFragBusinesses on Airtable {
    data {
      Businesses {
        data {
          Career_Category: Product_Categories
          Subtitle: Description
          Province_Territory: Province_Territory_State
          Name
          Web_URL: Web_Site
          Indigenous_Community : Community {
            data {
              Name
            }
          }
          Logo {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 297, height: 178)
              }
            }
          }
        }
      }
    }
  }
  fragment CommonFragBusinesses on PrismicListingCommon {
    data {
      community_btn_label
      businesses_header {
        richText
      }
      businesses_description {
        richText
      }
    }
  }
`