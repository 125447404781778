import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from '../Container'
import * as s from './Listing.module.scss'

export const ListingSources = ({data, labels}) => {


  if(!data.sources.length > 0) return (<></>)
  return (
    <section>
      <Container>
        <div>
          <PrismicRichText field={labels.sources_header?.richText}/>

          <ol>
            {data.sources.map((x,i) => (
              <li key={i}>
                {x.includes('http') || x.includes('www') ? 
                (
                  <PrismicLink href={x} className={s.ResourceLink}>{x}</PrismicLink>
                ) :
                  <p className={s.ResourceLink}>{x}</p>
                }
              </li>
            ))}
          </ol>
        </div>
      </Container>
    </section>
  )
}
export const query = graphql`
  fragment AirtableFragSources on Airtable {
    data {
      Native_Land_Link
      Wikipedia_URL
      Field_6
      Description_Source
      Community_URL
      Community_Link
      CCAB_Profile_Link
      GeocodeURL
    }
  }
  fragment CommonFragSources on PrismicListingCommon {
    data {
      sources_header {
        richText
      }
    }
  }
`