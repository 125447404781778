import React, {useState} from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from '../Container'
import * as s from './Listing.module.scss'
import {  FaVideo } from 'react-icons/fa'
import { MdPlayArrow } from 'react-icons/md'

export const ListingVideos = ({data, labels}) => {
  const [video, setVideo] = useState(0)
  return (
    <section>
      <Container>
        <div>
          <PrismicRichText field={labels.videos_header?.richText}/>
        </div>
        <div className={s.VideoPlayer}>
          <div className={s.VideoFrame}>
            <div className={s.VideoWrap}>
              <div dangerouslySetInnerHTML={{__html: data.videos[video].data.Embed}} />
            </div>
            
            <span className={s.VideoTitle}>{data.videos[video].data.Title}</span>
          </div>
          {data.videos.length > 1 && 
            <div className={s.VideoColumn}> 
              {data.videos?.map((vid, i) => (
                <a
                  onClick={() => setVideo(i)}
                  key={i}
                  className={video === i ? s.ActiveVideo : s.IdleVideo}
                >
                  <div className={s.VideoImage}>
                    <FaVideo/>
                  </div>
                  <div className={s.VideoToggle}>
                    {video === i && <span>{labels.videos_cur_btn?.toUpperCase()}</span>}
                    <p>{vid.data.Title}</p>
                    {video !== i &&<span><MdPlayArrow/> {labels.videos_now_btn}</span> } 
                  </div>
                  
                </a>
              ))}
            </div>     
          }    
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment AirtableFragVideos on Airtable {
    data {
      Videos {
        data {
          Title
          Embed
        }
      }
    }
  }
  fragment CommonFragVideos on PrismicListingCommon {
    data {
      videos_header {
        richText
      }
      videos_now_btn
      videos_cur_btn
    }
  }
`
